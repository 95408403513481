<template>
  <main>
    <div @click="$router.go(-1)" class="flex cursor-pointer items-center">
      <div class="p-2 border border-opacity-25 rounded-md border-blue-500">
        <svg
          class="w-5 h-5 fill-current text-blue-500"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2541 4.24106C16.5522 4.53326 16.5793 4.99051 16.3354 5.31272L16.2541 5.40503L9.52658 12L16.2541 18.595C16.5522 18.8872 16.5793 19.3444 16.3354 19.6666L16.2541 19.7589C15.956 20.0511 15.4896 20.0777 15.161 19.8386L15.0668 19.7589L7.7459 12.582C7.44784 12.2898 7.42074 11.8325 7.66461 11.5103L7.7459 11.418L15.0668 4.24106C15.3947 3.91965 15.9262 3.91965 16.2541 4.24106Z"
          />
        </svg>
      </div>
      <div class="ml-2 text-sm text-black">Back</div>
    </div>
    <h1 class="text-2xl ">
      New Notifications
    </h1>

    <div class="mt-5">
      <div
        class="text-red-600"
        :class="errorMssg !== '' ? 'my-5' : ''"
        v-if="errorMssg !== ''"
      >
        {{ errorMssg }}
      </div>
      <div class="  w-4/5">
        <p>Title <span class="text-red-600">*</span></p>
        <input
          type="text"
          class="py-4 pl-6 border-2 rounded-md border-gray-200 w-full mt-2"
          placeholder="Title"
          v-model="title"
          ref="title"
        />
      </div>
      <div class=" flex flex-col mt-5">
        <div>
          <p>Select Image:</p>
          <div>
            <input
              type="file"
              name=""
              id=""
              class="hidden"
              ref="inputFile"
              accept="image/*"
              @change="selectImage"
            />
          </div>

          <div class="flex items-center">
            <button
              @click="$refs.inputFile.click()"
              class="bg-blue-500  w-32 py-3 rounded-md mt-4  text-white"
            >
              Upload Image
            </button>
            <svg v-if="loading" class="spinner ml-4 " viewBox="0 0 50 50">
              <circle
                class="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
              ></circle>
            </svg>
          </div>
        </div>
        <div>
          <img
            v-if="showImage"
            class="w-40 h-40 mt-5 object-cover"
            :src="showImage"
            alt=""
          />
        </div>
      </div>
      <p class="mt-10 mb-3">Summary <span class="text-red-600">*</span></p>
      <textarea
        class="py-4 pl-6 border-2 rounded-md border-gray-200 w-full"
        placeholder="Summary"
        name=""
        id=""
        cols="30"
        rows="10"
        v-model="message"
        ref="message"
      ></textarea>
      <div class="flex justify-end items-end">
        <button
          @click="createMessage"
          class="bg-blue-500 py-3 px-4 rounded-md mt-10 text-white"
          id="createMessageBTN"
        >
          Create Message
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      title: "",
      url: "",
      imageToUpload: {
        src: null,
        file: null,
      },
      showImage: null,
      loading: false,
      message: "",
      errorMssg: "",
    };
  },
  methods: {
    ...mapActions(["CREATE_MESSAGE"]),
    async selectImage(e) {
      this.loading = true;
      this.imageToUpload.file = e.target.files[0];

      this.imageToUpload.src = window.URL.createObjectURL(
        this.imageToUpload.file
      );
      let res = await this.$store.dispatch("UPLOAD_FILE", this.imageToUpload);
      this.showImage = res.data;
      this.loading = false;
    },
    async createMessage() {
      let payload = {
        to: "",
        data: {
          title: this.title,
          message: this.message,
          url: this.showImage || "",
        },
      };
      if (this.title && this.message) {
        try {
          let res = await this.$store.dispatch("CREATE_MESSAGE", payload);
          if (res.status) {
            this.$router.push({
              name: "PreviewNotifications",
              params: {
                id: res.data._id,
              },
            });
          }
        } catch (error) {}
      } else {
        this.errorMssg = "Title and message is required.";
        if (!this.title) {
          this.$refs.title.focus();
        } else {
          this.$refs.message.focus();
        }
      }
    },
  },
};
</script>

<style></style>
